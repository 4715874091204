export enum PageLayout {
  Blank = 'blank',
  Center = 'center',
  Main = 'main',
}
// Sidebar types
export interface Page {
  title: string;
  icon: string;
  link?: string;
  subPages?: SubPage[];
}

export interface SubPage {
  title: string;
  link: string;
}

export interface Freelancer {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  username: string;
  jobsDone: number;
  earning: string;
  skills: string[];
  rating: number;
}

export interface FilterConfig {
  rating: {
    from: number;
    to: number;
  };
  jobsDone: {
    from: number;
    to?: number;
  };
  skills: string[];
}

export interface CfilterConfig {
  jobsPosted: {
    from: number;
    to?: number;
  };
  businessAreas: string[];
}

export interface ActivityFilterConfig {
  date: {
    from?: string;
    to: string;
  };
}

export interface WfilterConfig {
  status: string[];
}

export interface Client {
  id: string;
  businessName?: string;
  businessBio?: string;
  businessType?: string;
  businessSize?: string;
  companyLogo?: string;
  username: string;
  email: string;
  profileImage?: string;
  bannerImage?: string;
  firstName?: string;
  lastName?: string;
  gender?: string;
  phone?: string;
  dateOfBirth?: string;
  initial?: string;
  profileColor?: string;
  accountType?: string;
  slug: string;
  activeAccountType?: string;
  otherAccount?: number;
  otherAccountType?: string;
  bio?: string;
}


export interface ActivityLog {
  id: string;
  user: {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    isSuperuser: boolean;
    profileImage: string | null;
  };
  action: string;
  details: string;
  timestamp: string;
}
export interface User {
  id: string;
  names?: string;
  firstName: string;
  lastName: string;
  username: string;
  userId: string;
  email: string;
  profileImage?: string;
}

export interface Staff {
  id: string;
  firstName: string;
  lastName: string;
}

export interface Bank {
  id: string;
  names: string;
  code: string;
  number: string;
  isApproved: boolean;
  isDenied: boolean;
}

export interface Withdrawal {
  id: string;
  user: User;
  amount: number;
  status: string;
  image?: string;
  narration?: string;
  toWallet: boolean;
  fromSelf: boolean;
  refNo?: string;
  staff?: Staff;
  bank?: Bank;
  updatedAt: string;
  createdAt: string;
}

export interface Portfolio {
  id: string;
  title: string;
  user: {
    id: string;
    userId: number;
    freelancerId: number;
    email: string;
  };
  media: string[];
  toolsUsed: string[];
  creativeFields: string[];
  coverImage?: string;
  content: string[];
  isProject: boolean;
  projectSlug?: string;
  date: string;
  description?: string;
  slug: string;
  thumbNail?: string;
  status: 'DRAFT' | 'PUBLISHED';
  originalCoverImage?: string;
  cropZoomLevel?: number;
  cropPosition?: string;
  comments?: PortfolioCommentsType[];
  username?: string;
  avatar?: string;
  totalLikes: number;
  totalComments: number;
  userSlug?: string;
  initial?: string;
  profileColor?: string;
}

export type PortfolioCommentsType = {
  id: string;
  date: string;
  comment: string;
  username: string;
  profileImage?: string;
  initial?: string;
  profileColor?: string;
};

export interface ScaleType {
  (d: object): any;
  range: Function;
  domain: Function;
  ticks: Function;
}

export interface DimensionsType {
  marginTop: number;
  marginRight: number;
  marginBottom: number;
  marginLeft: number;
  height: number;
  width: number;
  boundedHeight?: number;
  boundedWidth?: number;
}

export interface AccessorType {
  (value: any): any;
}

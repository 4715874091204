<div class="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
  <div
    class="bg-white border border-gray-200 flex gap-2 justify-between items-end p-4 rounded-md"
  >
    <div class="space-y-2">
      <h4 class="text-sm font-bold tracking-wide uppercase text-gray-400">
        TOTAL USERS
      </h4>

      <h2 class="text-3xl font-light tracking-wide uppercase text-gray-800">
        {{ metrics ? metrics.freelancers : "0" }}
      </h2>

      <div class="flex flex-row items-center gap-2">
        <h4
          class="bg-green-100 px-2 py-1 rounded-full text-xs font-semibold tracking-wide uppercase text-green-500 w-fit"
        >
          <ng-icon class="text-green-500" name="remixArrowUpFill"></ng-icon
          ><span>0%</span>
        </h4>
        <span class="text-xs text-gray-500 font-thin"
          >Compared to last month</span
        >
      </div>
    </div>

    <!-- <div class="bg-secondary-700 p-2 rounded-full h-10 w-10 flex justify-center items-center">
            <ng-icon size="1.5rem" class="text-secondary-100" name="remixGroupLine"></ng-icon>
        </div> -->
  </div>

  <div
    class="bg-white border border-gray-200 flex gap-2 justify-between items-end p-4 rounded-md"
  >
    <div class="space-y-2">
      <h4 class="text-sm font-bold tracking-wide uppercase text-gray-400">
        PAYOUTS
      </h4>

      <h2 class="text-3xl font-light tracking-wide uppercase text-gray-800">
        ₦{{ metrics ? metrics.payouts : "0" }}
      </h2>

      <div class="flex flex-row items-center gap-2">
        <h4
          class="bg-red-100 px-2 py-1 rounded-full text-xs font-semibold tracking-wide uppercase text-red-500 w-fit"
        >
          <ng-icon class="text-red-500" name="remixArrowDownFill"></ng-icon
          ><span>0%</span>
        </h4>
        <span class="text-xs text-gray-500 font-thin"
          >Compared to last month</span
        >
      </div>
    </div>

    <!-- <div class="bg-accent-700 p-2 rounded-full h-10 w-10 flex justify-center items-center">
            <ng-icon size="1.5rem" class="text-accent-100" name="remixGroupLine"></ng-icon>
        </div> -->
  </div>

  <div
    class="bg-white border border-gray-200 flex gap-2 justify-between items-end p-4 rounded-md"
  >
    <div class="space-y-2">
      <h4 class="text-sm font-bold tracking-wide uppercase text-gray-400">
        JOB LISTINGS
      </h4>

      <h2 class="text-3xl font-light tracking-wide uppercase text-gray-800">
        {{ metrics ? metrics.completedJobs : "0" }}
      </h2>

      <div class="flex flex-row items-center gap-2">
        <h4
          class="bg-green-100 px-2 py-1 rounded-full text-xs font-semibold tracking-wide uppercase text-green-500 w-fit"
        >
          <ng-icon class="text-green-500" name="remixArrowUpFill"></ng-icon
          ><span>0%</span>
        </h4>
        <span class="text-xs text-gray-500 font-thin"
          >Compared to last month</span
        >
      </div>
    </div>

    <!-- <div class="bg-primary-700 p-2 rounded-full h-10 w-10 flex justify-center items-center">
            <ng-icon size="1.5rem" class="text-primary-100" name="remixGroupLine"></ng-icon>
        </div> -->
  </div>
</div>

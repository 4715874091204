import { Component, Input } from '@angular/core';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { remixArrowDownFill, remixArrowUpFill, remixGroupLine } from '@ng-icons/remixicon';
import { Metrics } from '../../pages/dashboard/dashboard.component';

@Component({
  selector: 'app-dashboard-cards',
  standalone: true,
  imports: [NgIconComponent],
  providers: [
    provideIcons({
      remixGroupLine,
      remixArrowUpFill,
      remixArrowDownFill
    }),
  ],
  templateUrl: './dashboard-cards.component.html'
})
export class DashboardCardsComponent {
  @Input() metrics: Metrics | null
}

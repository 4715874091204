<div
  class="chart-container relative bg-gradient-to-br from-white to-gray-50 border border-gray-200 rounded-xl shadow-sm overflow-hidden"
>
  <div
    class="absolute top-0 left-0 w-full h-16 bg-gradient-to-r from-primary-500/10 to-secondary-500/10"
  ></div>

  <!-- Header with animated total and period selector -->
  <div class="p-6 pb-2 relative">
    <div
      class="flex flex-col items-start gap-4"
    >
      <div class="space-y-1">
        <h3 class="text-gray-700 text-sm font-medium uppercase tracking-wider">
          Revenue Overview
        </h3>
        <div class="flex flex-wrap items-baseline gap-2">
          <div class="flex items-end gap-2">
            <p
              class="font-semibold text-3xl text-gray-800"
              [countUp]="totalRevenue"
            >
              0
            </p>
            <span class="text-sm text-gray-500">NGN</span>
          </div>

          <!-- Change indicator -->
          <div
            class="flex items-center ml-2 py-0.5 px-2 rounded-full"
            [ngClass]="
              percentChange >= 0
                ? 'bg-green-100 text-green-800'
                : 'bg-red-100 text-red-800'
            "
          >
            <ng-icon
              [name]="
                percentChange >= 0 ? 'remixArrowUpSLine' : 'remixArrowDownSLine'
              "
              class="mr-1"
            ></ng-icon>
            <span class="text-xs font-bold"
              >{{ percentChange | number : "1.1-1" }}%</span
            >
          </div>
        </div>
      </div>

      <!-- Period selector -->
      <div class="flex flex-wrap items-center gap-2 self-end">
        <button
          *ngFor="let period of periods"
          [ngClass]="
            selectedPeriod === period.value
              ? 'bg-primary-100 text-primary-800 ring-1 ring-primary-400'
              : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
          "
          (click)="changePeriod(period.value)"
          class="px-3 py-1 rounded-md text-xs font-medium transition-all duration-200"
        >
          {{ period.label }}
        </button>
      </div>
    </div>

    <!-- Chart description -->
    <p class="text-sm text-gray-500 mt-2">{{ chartDescription }}</p>
  </div>

  <!-- Interactive chart -->
  <div class="px-4 pt-2 pb-4 chart-area">
    <div class="relative h-64 w-full">
      <div
        *ngIf="isLoading"
        class="absolute inset-0 flex items-center justify-center bg-white/50 z-10"
      >
        <div
          class="animate-spin h-8 w-8 border-4 border-primary-500 border-t-transparent rounded-full"
        ></div>
      </div>

      <canvas
        *ngIf="isBrowser"
        #chartCanvas
        baseChart
        [data]="lineChartData"
        [options]="lineChartOptions"
        [type]="'line'"
        (chartHover)="onChartHover($event)"
        (chartClick)="onChartClick($event)"
      >
      </canvas>
    </div>
  </div>

  <!-- Insights panel -->
  <div
    class="grid grid-cols-1 sm:grid-cols-3 sm:divide-x divide-y divide-gray-200 bg-gray-50"
  >
    <div *ngFor="let metric of metrics" class="py-3 px-4">
      <p class="text-xs text-gray-500 font-medium">{{ metric.label }}</p>
      <p class="text-lg font-semibold text-gray-800">
        {{ metric.value | currency : "NGN" : "symbol" : "1.0-0" }}
      </p>
      <div class="flex items-center mt-1">
        <span
          class="text-xs"
          [ngClass]="metric.change >= 0 ? 'text-green-600' : 'text-red-600'"
        >
          <ng-icon
            [name]="
              metric.change >= 0 ? 'remixArrowUpSLine' : 'remixArrowDownSLine'
            "
            class="mr-0.5"
          ></ng-icon>
          {{ metric.change | number : "1.1-1" }}%
        </span>
        <span class="text-xs text-gray-500 ml-1">vs prev.</span>
      </div>
    </div>
  </div>
</div>

import { CommonModule, NgIf, isPlatformBrowser } from '@angular/common';
import { Component, EventEmitter, Inject, Input, OnInit, Output, PLATFORM_ID } from '@angular/core';

@Component({
  selector: 'app-dialog',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './dialog.component.html',
})
export class DialogComponent implements OnInit {
  @Input({ required: true }) open: boolean = false;
  @Output() close = new EventEmitter();
  
  isMobile: boolean = false;
  
  constructor(@Inject(PLATFORM_ID) private platformId: Object) {}
  
  ngOnInit(): void {
    this.checkScreenSize();
    
    if (isPlatformBrowser(this.platformId)) {
      window.addEventListener('resize', () => {
        this.checkScreenSize();
      });
    }
  }
  
  checkScreenSize(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.isMobile = window.innerWidth < 640; // sm breakpoint in Tailwind is 640px
    }
  }

  closeOnBackdrop(event: MouseEvent): void {
    // Only close if we're on desktop
    if (!this.isMobile) {
      event.preventDefault();
      this.closeModal();
    }
  }

  closeModal(): void {
    this.close.emit();
  }
}

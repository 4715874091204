import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Observable, from } from 'rxjs';
import { ApiRequest } from '../utils/api-request';
import { USER_LOGIN_MUTATION } from '../utils/graphql/auth-mutations';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
    this.platformId = platformId;
  }

  login(email: string, password: string): Observable<any> {
    const req = ApiRequest(
      USER_LOGIN_MUTATION,
      { email, password },
      this.platformId,
      'adminLogin'
    );

    return from(req);
  }

  /* logout(): Observable<any> {
  } */
}

import {
  Component,
  Inject,
  PLATFORM_ID,
  ViewChild,
  AfterViewInit,
  ElementRef,
  OnInit,
} from '@angular/core';

import {
  isPlatformBrowser,
  NgIf,
  NgClass,
  DecimalPipe,
  CurrencyPipe,
  NgFor,
} from '@angular/common';
import { Chart, ChartData, ChartOptions, registerables } from 'chart.js';
import { NgChartsModule } from 'ng2-charts';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { remixArrowUpSLine, remixArrowDownSLine } from '@ng-icons/remixicon';
import { CountUpDirective } from './directives/count-up.directive';

// Create Gradient Animation Plugin for Chart.js
const gradientPlugin = {
  id: 'gradientAnimation',
  beforeDraw: (chart: any) => {
    if (chart.tooltip?._active?.length) {
      const ctx = chart.ctx;
      const x = chart.tooltip._active[0].element.x;
      const yAxis = chart.scales.y;
      const topY = yAxis.top;
      const bottomY = yAxis.bottom;

      // Draw vertical line
      ctx.save();
      ctx.beginPath();
      ctx.moveTo(x, topY);
      ctx.lineTo(x, bottomY);
      ctx.lineWidth = 1;
      ctx.strokeStyle = 'rgba(14, 117, 109, 0.5)';
      ctx.stroke();
      ctx.restore();

      // Draw point highlight
      ctx.save();
      ctx.beginPath();
      ctx.arc(x, chart.tooltip._active[0].element.y, 8, 0, 2 * Math.PI);
      ctx.fillStyle = 'rgba(14, 117, 109, 0.2)';
      ctx.fill();
      ctx.strokeStyle = '#0e756d';
      ctx.lineWidth = 2;
      ctx.stroke();
      ctx.restore();
    }
  },
};

Chart.register(...registerables);

@Component({
  selector: 'app-revenue-chart',
  templateUrl: './revenue-chart.component.html',
  styleUrls: ['./revenue-chart.component.scss'],
  imports: [
    NgIf,
    NgFor,
    NgClass,
    NgChartsModule,
    NgIconComponent,
    DecimalPipe,
    CurrencyPipe,
    CountUpDirective,
  ],
  providers: [provideIcons({ remixArrowUpSLine, remixArrowDownSLine })],
  standalone: true,
})
export class RevenueChartComponent implements OnInit, AfterViewInit {
  @ViewChild('chartCanvas') chartCanvas!: ElementRef;
  isBrowser = false;
  isLoading = false;
  totalRevenue: number = 128500000;
  percentChange: number = 8.3;
  chartDescription: string =
    'Monthly revenue performance from Mar 2024 to Feb 2025';

  // Period selection
  selectedPeriod: string = '1Y';
  periods = [
    { label: '30D', value: '30D' },
    { label: '3M', value: '3M' },
    { label: '6M', value: '6M' },
    { label: '1Y', value: '1Y' },
    { label: 'All', value: 'ALL' },
  ];

  // Metrics displayed at the bottom
  metrics = [
    { label: 'Average', value: 15062500, change: 6.2 },
    { label: 'Highest', value: 19000000, change: 8.5 },
    { label: 'Lowest', value: 11000000, change: -3.8 },
  ];

  // Dataset with animation
  lineChartData: ChartData<'line'> = {
    labels: [
      'Mar 2024',
      'Apr 2024',
      'May 2024',
      'Jun 2024',
      'Jul 2024',
      'Aug 2024',
      'Sep 2024',
      'Oct 2024',
      'Nov 2024',
      'Dec 2024',
      'Jan 2025',
      'Feb 2025',
    ],
    datasets: [
      {
        label: 'Revenue',
        data: [
          12000000, 13200000, 14000000, 14500000, 12800000, 11600000, 11000000,
          13300000, 15600000, 17500000, 19000000, 18500000,
        ],
        borderColor: '#0e756d',
        backgroundColor: (context) => {
          const chart = context.chart;
          const { ctx, chartArea } = chart;

          if (!chartArea) {
            return 'rgba(14, 117, 109, 0.2)';
          }

          const gradient = ctx.createLinearGradient(
            0,
            chartArea.bottom,
            0,
            chartArea.top
          );
          gradient.addColorStop(0, 'rgba(14, 117, 109, 0)');
          gradient.addColorStop(0.6, 'rgba(14, 117, 109, 0.2)');
          gradient.addColorStop(1, 'rgba(14, 117, 109, 0.4)');
          return gradient;
        },
        borderWidth: 2,
        fill: true,
        pointRadius: 0, // Hide points by default
        pointHoverRadius: 6,
        pointBackgroundColor: '#0e756d',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#0e756d',
        pointHoverBorderColor: '#fff',
        pointHoverBorderWidth: 2,
        tension: 0.4,
      },
    ],
  };

  lineChartOptions: ChartOptions<'line'> = {
    responsive: true,
    maintainAspectRatio: false,
    animations: {
      tension: {
        duration: 1000,
        easing: 'easeOutQuart',
        from: 0.5,
        to: 0.4,
        loop: false,
      },
      radius: {
        duration: 400,
        easing: 'linear',
        loop: (ctx) => ctx.active,
      },
    },
    interaction: {
      intersect: false,
      mode: 'index',
    },
    plugins: {
      tooltip: {
        enabled: true,
        backgroundColor: 'rgba(31, 41, 55, 0.9)',
        titleColor: '#fff',
        bodyColor: '#d1d5db',
        borderColor: '#374151',
        borderWidth: 1,
        padding: 12,
        cornerRadius: 6,
        displayColors: false,
        titleFont: {
          size: 14,
          weight: 'bold',
        },
        bodyFont: {
          size: 13,
        },
        callbacks: {
          title: (items) => {
            return items[0].label;
          },
          label: (tooltipItem) => {
            return `Revenue: ₦${(tooltipItem.raw as number).toLocaleString(
              'en-NG'
            )}`;
          },
          footer: (tooltipItems) => {
            const percent = this.getMonthlyPercentChange(
              tooltipItems[0].dataIndex
            );
            return percent > 0
              ? `+${percent.toFixed(1)}% vs previous month`
              : `${percent.toFixed(1)}% vs previous month`;
          },
        },
      },
      legend: { display: false },
    },
    scales: {
      x: {
        ticks: {
          color: '#666',
          font: {
            size: 10,
          },
        },
        grid: { display: false },
        border: {
          display: false,
        },
      },
      y: {
        beginAtZero: false,
        ticks: {
          color: '#666',
          font: {
            size: 11,
          },
          callback: (tickValue: string | number) =>
            `₦${(Number(tickValue) / 1_000_000).toFixed(0)}M`,
        },
        grid: {
          color: '#e5e7eb',
          drawTicks: false,
        },
        border: {
          display: false,
        },
      },
    },
  };

  constructor(@Inject(PLATFORM_ID) private platformId: any) {}

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.isBrowser = true;
      Chart.register(...registerables);
      // Register custom plugin
      Chart.register(gradientPlugin);
    }
  }

  ngAfterViewInit() {
    // Initial render is complete
  }

  // Get percent change between consecutive months
  getMonthlyPercentChange(currentIndex: number): number {
    if (currentIndex === 0) return 0;

    const currentValue = this.lineChartData.datasets[0].data[
      currentIndex
    ] as number;
    const previousValue = this.lineChartData.datasets[0].data[
      currentIndex - 1
    ] as number;

    if (previousValue === 0) return 0;
    return ((currentValue - previousValue) / previousValue) * 100;
  }

  // Handle period change
  changePeriod(period: string) {
    this.isLoading = true;
    this.selectedPeriod = period;

    // Simulate data loading
    setTimeout(() => {
      // Adjust data based on selected period
      let newData: number[] = [];
      let newLabels: string[] = [];

      switch (period) {
        case '30D':
          newLabels = ['Week 1', 'Week 2', 'Week 3', 'Week 4'];
          newData = [4200000, 4500000, 5100000, 4800000];
          this.chartDescription =
            'Weekly revenue performance over the last 30 days';
          this.totalRevenue = 18600000;
          this.percentChange = 5.8;
          break;

        case '3M':
          newLabels = ['Dec 2024', 'Jan 2025', 'Feb 2025'];
          newData = [17500000, 19000000, 18500000];
          this.chartDescription =
            'Monthly revenue performance over the last 3 months';
          this.totalRevenue = 55000000;
          this.percentChange = 12.5;
          break;

        case '6M':
          newLabels = [
            'Sep 2024',
            'Oct 2024',
            'Nov 2024',
            'Dec 2024',
            'Jan 2025',
            'Feb 2025',
          ];
          newData = [
            11000000, 13300000, 15600000, 17500000, 19000000, 18500000,
          ];
          this.chartDescription =
            'Monthly revenue performance over the last 6 months';
          this.totalRevenue = 94900000;
          this.percentChange = 16.2;
          break;

        case '1Y':
          // Keep full year data (default)
          newLabels = this.lineChartData.labels as string[];
          newData = this.lineChartData.datasets[0].data as number[];
          this.chartDescription =
            'Monthly revenue performance from Mar 2024 to Feb 2025';
          this.totalRevenue = 128500000;
          this.percentChange = 8.3;
          break;

        case 'ALL':
          // Extend with more historical data
          newLabels = [
            'Mar 2023',
            'Jun 2023',
            'Sep 2023',
            'Dec 2023',
            'Mar 2024',
            'Jun 2024',
            'Sep 2024',
            'Dec 2024',
            'Feb 2025',
          ];
          newData = [
            9500000, 10200000, 10800000, 11500000, 12000000, 14500000, 11000000,
            17500000, 18500000,
          ];
          this.chartDescription =
            'Quarterly revenue performance from Mar 2023 to Feb 2025';
          this.totalRevenue = 215500000;
          this.percentChange = 10.6;
          break;
      }

      // Update chart data
      this.lineChartData = {
        labels: newLabels,
        datasets: [
          {
            ...this.lineChartData.datasets[0],
            data: newData,
          },
        ],
      };

      // Update metrics
      this.metrics = [
        {
          label: 'Average',
          value: newData.reduce((sum, val) => sum + val, 0) / newData.length,
          change: period === '1Y' ? 6.2 : period === '30D' ? 3.1 : 7.5,
        },
        {
          label: 'Highest',
          value: Math.max(...newData),
          change: period === '1Y' ? 8.5 : period === '30D' ? 6.8 : 9.2,
        },
        {
          label: 'Lowest',
          value: Math.min(...newData),
          change: period === '1Y' ? -3.8 : period === '30D' ? 1.5 : -2.7,
        },
      ];

      this.isLoading = false;
    }, 600);
  }

  // Chart interaction handlers
  onChartHover(event: any) {
  }

  onChartClick(event: any) {
    if (event.active && event.active.length > 0) {
      const dataIndex = event.active[0].index;
      const datasetIndex = event.active[0].datasetIndex;
      const value = this.lineChartData.datasets[datasetIndex].data[dataIndex];
      const label = this.lineChartData.labels?.[dataIndex];

      console.log(`Clicked on ${label}: ${value}`);
    }
  }
}

<div
  class="py-2 px-6 bg-white flex items-center sticky top-0 left-0 z-20 border-b border-gray-100"
>
  <button
    type="button"
    class="text-lg text-gray-600 sidebar-toggle"
    (click)="sidebarToggle($event)"
  >
    <ng-icon name="remixMenuLine"></ng-icon>
  </button>

  <a href="#" class="flex items-center">
    <img
      [src]="'../../../assets/ngd-logo.png'"
      alt="Ngd Logo"
      class="w-auto rounded object-cover h-8 pl-4"
    />
  </a>

  <ul class="flex items-center text-sm ml-4">
    <!-- <li class="mr-2">
            <a href="#" class="hover:text-gray-400 font-medium"><span [ngClass]="parentPageExists ? 'text-gray-400' : 'text-gray-800'">{{pTitle}}</span><span *ngIf="parentPageExists" class="text-gray-200">&nbsp;/</span><span
                    *ngIf="parentPageExists">&nbsp;{{sTitle}}</span></a>
        </li> -->
  </ul>
  <ul class="ml-auto flex items-center">
    <li class="mr-1 dropdown">
      <button
        type="button"
        class="dropdown-toggle text-gray-400 w-8 h-8 rounded flex items-center justify-center hover:bg-gray-50 hover:text-gray-600"
      >
        <ng-icon name="remixSearchLine"></ng-icon>
      </button>
    </li>
    <li
      *ngIf="user"
      class="dropdown p-1 flex items-center gap-2 bg-gray-100 rounded-full"
    >
      <span class="text-sm text-gray-500 pl-2 hidden sm:inline-block">{{
        user.username ? user.username : user.email
      }}</span>
      <img
        [src]="
          user.profileImage
            ? user.profileImage
            : '../../../assets/blank-avatar.png'
        "
        alt=""
        class="w-8 h-8 rounded-full block object-cover align-middle"
      />
    </li>

    <button
      (click)="logout()"
      type="button"
      class="dropdown-toggle text-red-400 w-8 h-8 rounded flex items-center justify-center hover:bg-red-50 hover:text-red-600"
    >
      <ng-icon name="remixLogoutBoxLine"></ng-icon>
    </button>
  </ul>
</div>

import { Pipe, PipeTransform } from '@angular/core';
import { WfilterConfig, Withdrawal } from '../../../types';

@Pipe({ name: 'withdrawalsSort', standalone: true })
export class WithdrawalsSortPipe implements PipeTransform {
  transform(
    values: Withdrawal[],
    search: string,
    locale: 'all' | 'email' | 'name' | 'username',
    filters: WfilterConfig
  ): Withdrawal[] {
    let searchResults;
    if (values.length === 0) {
      return values;
    }

    searchResults = values.filter((value: Withdrawal) => {
      const nameFound =
        value.user.firstName.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
        value.user.lastName.toLowerCase().indexOf(search.toLowerCase()) !== -1;

      const emailFound =
        value.user.email.toLowerCase().indexOf(search.toLowerCase()) !== -1;

      const usernameFound =
        value.user.username.toLowerCase().indexOf(search.toLowerCase()) !== -1;

      if (locale === 'all') {
        if (nameFound || emailFound || usernameFound) {
          return value;
        }
      }

      if (locale === 'name') {
        if (nameFound) {
          return value;
        }
      }

      if (locale === 'email') {
        if (emailFound) {
          return value;
        }
      }

      if (locale === 'username') {
        if (usernameFound) {
          return value;
        }
      }

      return;
    });

    if (!search || search.length === 0) {
      searchResults = values;
    }

    const filterResults = searchResults.filter((value: Withdrawal) => {
      let amongBusiness;

      if (filters.status.length === 0) {
        amongBusiness = true;
      } else {
        amongBusiness = filters.status.some(s => s == value.status)
      }

      if (amongBusiness) return value
      
      else return;
    });

    return filterResults;
  }
}

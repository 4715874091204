// Countup Directive
import { isPlatformBrowser } from '@angular/common';
import { Directive, ElementRef, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';

@Directive({
  selector: '[countUp]',
  standalone: true
})
export class CountUpDirective implements OnInit {
  @Input() countUp!: number;
  @Input() duration: number = 1500;
  
  private startTimestamp: number | null = null;
  private startValue: number = 0;
  
  constructor(private el: ElementRef, @Inject(PLATFORM_ID) private platformId: Object) {
    this.platformId = platformId
  }
  
  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      window.requestAnimationFrame(this.animate.bind(this));
    }
  }
  
  animate(timestamp: number) {
    if (!this.startTimestamp) this.startTimestamp = timestamp;
    const progress = Math.min((timestamp - this.startTimestamp) / this.duration, 1);
    
    const currentValue = Math.floor(progress * (this.countUp - this.startValue) + this.startValue);
    this.el.nativeElement.textContent = currentValue.toLocaleString('en-NG');
    
    if (progress < 1) {
      window.requestAnimationFrame(this.animate.bind(this));
    } else {
      this.el.nativeElement.textContent = this.countUp.toLocaleString('en-NG');
    }
  }
}

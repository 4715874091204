<div
  id="sidebar-menu"
  class="fixed left-0 top-0 h-full bg-white p-4 z-40 transition-all ease-in-out border-r border-gray-200"
  [ngClass]="collapse ? 'w-20' : 'w-64'"
>
  <!-- <a href="#" class="flex items-center pb-4 border-b border-b-secondary-600"
    [ngClass]="collapse ? 'items-center justify-center' : 'items-center'">
    <img [src]="collapse ? '../../../assets/icon.png' : '../../../assets/ngd-logo.png'" alt="Ngd Logo"
      class="w-auto rounded object-cover" [ngClass]="collapse ? 'h-7' : 'h-8'" />
  </a> -->

  <a href="#" class="flex md:hidden items-center border-b pb-4 border-b-gray-200">
    <img
      [src]="'../../../assets/ngd-logo.png'"
      alt="Ngd Logo"
      class="w-auto rounded object-cover h-8 pl-4"
    />
  </a>

  <div class="hidden md:flex pl-3 pb-4 border-b border-b-gray-200">
    <button (click)="toggleCollapse()">
      <ng-icon
        [ngClass]="collapse ? 'rotate-180' : 'rotate-0'"
        size="1.5rem"
        class="text-gray-500 transition-all"
        name="featherSidebar"
      ></ng-icon>
    </button>
  </div>

  <ul class="mt-4">
    <app-sidebar-link
      (emitClick)="openDrop($event)"
      [collapse]="collapse"
      *ngFor="let page of pages"
      [page]="page"
    ></app-sidebar-link>
  </ul>
</div>

<div
  id="sidebar-overlay"
  (click)="toggleSidebarOverlay($event)"
  class="fixed top-0 left-0 w-full h-full bg-black/50 z-30 md:hidden"
></div>

<button class="btn-secondary" (click)="toggleDropdown($event)" #btnDropdownRef>

    <ng-icon size="1.15rem" name="remixFilter3Line"></ng-icon>

    <span>Filter</span>
</button>

<div class="popel max-w-60 z-50" #popoverDropdownRef>

    <div class="popel p-4 space-y-4 bg-white text-base float-left list-none rounded shadow-lg mt-2 min-w-48 ring-1 ring-gray-200"
        [ngClass]="dropdownPopoverShow ? 'block' : 'hidden'">
        <div>
            <h4 class="text-xs font-bold uppercase tracking-tight text-gray-500 mb-2">Business Area</h4>

            <app-multi-select [clear]="clearStatuses" (selectedLists)="setSelectedLists($event)"
                [data]="statusData"></app-multi-select>
        </div>

        <button (click)="clearFilters()"
            class="text-sm text-secondary-500 font-bold underline underline-offset-4">&times; clear filters</button>

        <button (click)="applyFilters()"
            class="flex items-center justify-center w-full px-5 py-2 text-xs font-semibold tracking-wide text-white transition-colors duration-200 bg-secondary-900 rounded-md shrink-0 gap-x-2 hover:bg-gray-600">APPLY
            FILTERS</button>
    </div>

</div>
<div class="flex items-center justify-between mt-4 w-full">
  <div class="flex items-center gap-x-2">
    <label for="itemsPerPage" class="text-sm text-gray-500">Items per page:</label>
    <select id="itemsPerPage" [ngModel]="itemsPerPage" (change)="onItemsPerPageChange($event)" class="block py-2 px-4 text-gray-700 bg-white rounded-md ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-secondary-500">
      <option *ngFor="let option of itemsPerPageOptions" [value]="option">{{ option }}</option>
    </select>
  </div>

  <div class="text-sm text-gray-500">
    Page <span class="font-medium text-gray-700">{{ currentPage }}</span> of <span class="font-medium text-gray-700">{{ totalPages }}</span>
  </div>

  <div class="flex items-center gap-x-2">
    <button class="btn-plain" [disabled]="currentPage === 1" (click)="previousPage()">
      <ng-icon name="remixArrowLeftLine"></ng-icon>
      Previous
    </button>
    <div class="flex items-center gap-x-1">
      <button *ngFor="let page of getPages()" class="btn-plain" [class.active]="page === currentPage" (click)="goToPage(page)">
        {{ page }}
      </button>
    </div>
    <button class="btn-plain" [disabled]="currentPage === totalPages" (click)="nextPage()">
      Next
      <ng-icon name="remixArrowRightLine"></ng-icon>
    </button>
  </div>
</div>

export const GET_WITHDRAWALS_QUERY = `
query GetWithdrawals {
    withdrawals {
      id
      user {
        id
        names
        firstName
        lastName
        username
        userId
        email
        profileImage
      }
      amount
      status
      image
      narration
      toWallet
      fromSelf
      refNo
      staff {
        id
        firstName
        lastName
      }
      bank {
        id
        names
        code
        number
        isApproved
        isDenied
      }
      updatedAt
      createdAt
    }
  }`;

export const GET_PORTFOLIOS_QUERY = `
query GetPortfolios {
portfolios {
    edges {
      node {
        id
        title
        user {
          id
          userId
          freelancerId
          email
        }
        media
        toolsUsed
        creativeFields
        coverImage
        isProject
        projectSlug
        date
        description
        slug
        thumbNail
        status
        totalLikes
        totalComments
        userSlug
        username
      }
      cursor
    }
    pageInfo {
      hasNextPage
      endCursor
    }
  }
}
`;

import { NgFor, NgIf, isPlatformBrowser } from '@angular/common';
import {
  Component,
  Inject,
  PLATFORM_ID,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import {
  remixArrowLeftLine,
  remixArrowRightLine,
  remixDownload2Line,
  remixFilter3Line,
  remixSearch2Line,
} from '@ng-icons/remixicon';
import { clientsData } from '../../../../clients-data';
import { Client, CfilterConfig } from '../../../../types';
import {
  CsortableHeaderDirective,
  SortEvent,
  compare,
} from '../../../directives/csortable-header/csortable-header.directive';
import { ClientsSortPipe } from '../../../pipes/clients-sort/clients-sort.pipe';
import { CtableDropdownComponent } from '../../../ui/ctable-dropdown/ctable-dropdown.component';
import { CtableFilterComponent } from '../../../ui/ctable-filter/ctable-filter.component';
import { DialogComponent } from '../../../ui/dialog/dialog.component';
import { ListSelectComponent } from '../../../ui/list-select/list-select.component';
import { GET_CLIENTS_QUERY } from '../../../utils/graphql/clients-queries';
import { from } from 'rxjs';
import { ApiRequest } from '../../../utils/api-request';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-clients',
  standalone: true,
  imports: [
    NgIconComponent,
    ListSelectComponent,
    NgFor,
    NgIf,
    CsortableHeaderDirective,
    ClientsSortPipe,
    FormsModule,
    CtableDropdownComponent,
    DialogComponent,
    CtableFilterComponent,
  ],
  providers: [
    provideIcons({
      remixFilter3Line,
      remixDownload2Line,
      remixSearch2Line,
      remixArrowRightLine,
      remixArrowLeftLine,
    }),
  ],
  templateUrl: './clients.component.html',
})
export class ClientsComponent {
  @ViewChildren(CsortableHeaderDirective)
  headers!: QueryList<CsortableHeaderDirective>;

  currentUser?: Client;
  open: boolean = false;
  data: Client[] = [];
  clients: Client[] | null = null;

  searchLocale: 'all' | 'email' | 'name' | 'username' = 'all';
  searchValue: string = '';
  searchFilters: string[] = ['All', 'Email', 'Name', 'Username'];
  tableFilters: CfilterConfig = {
    jobsPosted: { from: 0, to: undefined },
    businessAreas: [],
  };

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private toastr: ToastrService
  ) {
    this.platformId = platformId;
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      from(
        ApiRequest(GET_CLIENTS_QUERY, {}, this.platformId, 'allClients')
      ).subscribe({
        next: (result) => {
          this.data = result;
          this.clients = result;
        },
        error: (err) => {
          this.toastr.error(err, 'Error fetching data');
        },
      });
    }
  }

  setLocale(locale: string) {
    this.searchLocale = locale as 'all' | 'email' | 'name' | 'username';
  }

  onSort({ column, direction }: SortEvent) {
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    if (direction === '' || column === '') {
      this.clients = this.data;
    } else {
      this.clients = [...this.data].sort((a, b) => {
        const res = compare(a[column] ?? '', b[column] ?? '');

        return direction === 'asc' ? res : -res;
      });
    }
  }

  openDeactivateModal(value: { state: boolean; user: Client }) {
    this.open = value.state;
    this.currentUser = value.user;
  }

  closeModal() {
    this.open = false;
  }

  setFilters(filter: CfilterConfig) {
    this.tableFilters = filter;
  }
}

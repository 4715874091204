<h1 class="text-2xl font-light text-secondary-900 mb-8">Freelancers</h1>

<div class="space-y-6">
    <!-- Filter and Download -->
    <div class="flex items-center gap-x-3">
        <app-table-filter (onFilter)="setFilters($event)"></app-table-filter>
        <button class="btn-plain">
            <ng-icon name="remixDownload2Line"></ng-icon>

            <span>Download</span>
        </button>
    </div>
    <!--  -->

    <!-- Table Search -->
    <div class="flex items-center gap-2 flex-wrap w-full">
        <div class="relative flex items-center">
            <ng-icon class="absolute left-3 top-[25%] text-gray-400" name="remixSearch2Line"></ng-icon>

            <input type="text" [(ngModel)]="searchValue" placeholder="Search"
                class="block w-full py-1.5 pr-5 pl-10 text-gray-700 bg-white rounded-md placeholder-gray-400/70 ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-secondary-500">
        </div>
        <app-list-select [data]="searchFilters" (selectedList)="setLocale($event)"></app-list-select>
    </div>
    <!--  -->

    <div class="overflow-x-auto">
        <div class="inline-block min-w-full py-2 align-middle">
            <div class="overflow-hidden border border-gray-200 md:rounded-md">
                <table class="min-w-full divide-y divide-gray-200">
                    <thead class="bg-secondary-100/10">
                        <tr>
                            <th sortable="firstName" scope="col" (sort)="onSort($event)"
                                class="uppercase px-4 py-3.5 text-sm font-semibold text-left rtl:text-right text-gray-500">
                                Name
                            </th>

                            <th sortable="rating" scope="col" (sort)="onSort($event)"
                                class="uppercase px-4 py-3.5 text-sm font-semibold text-left rtl:text-right text-gray-500">
                                Rating
                            </th>

                            <th sortable="jobsDone" scope="col" (sort)="onSort($event)"
                                class="uppercase px-4 py-3.5 text-sm font-semibold text-left rtl:text-right text-gray-500">
                                Jobs Done
                            </th>

                            <th sortable="earning" scope="col" (sort)="onSort($event)"
                                class="uppercase px-4 py-3.5 text-sm font-semibold text-left rtl:text-right text-gray-500">
                                Earnings (₦)
                            </th>

                            <th scope="col"
                                class="uppercase px-4 py-3.5 text-sm font-semibold text-left rtl:text-right text-gray-500">
                                Skills</th>

                            <th scope="col"
                                class="uppercase px-4 py-3.5 text-sm font-semibold text-left rtl:text-right text-gray-500">
                                Actions
                            </th>
                        </tr>
                    </thead>
                    
                    <tbody class="bg-white divide-y divide-gray-200 ">
                        <tr *ngFor="let freelancer of freelancers | freelancersSort:searchValue:searchLocale:tableFilters">
                            <td class="px-4 py-4 text-sm font-medium whitespace-nowrap">
                                <div>
                                    <h2 class="font-medium text-gray-800">
                                        {{freelancer.firstName}} {{freelancer.lastName}}
                                    </h2>
                                </div>
                            </td>
                            <td class="px-4 py-4 text-sm font-medium whitespace-nowrap">
                                <app-star-rating [value]="freelancer.rating"></app-star-rating>
                            </td>
                            <td class="px-4 py-4 text-sm whitespace-nowrap">
                                <div>
                                    <h4 class="text-gray-700 font-semibold">{{freelancer.jobsDone}}
                                    </h4>
                                </div>
                            </td>
                            <td class="px-4 py-4 text-sm whitespace-nowrap">
                                <div>
                                    <p class="font-semibold text-gray-500">
                                        {{freelancer.earning}}
                                    </p>
                                </div>
                            </td>
                            <td class="px-4 py-4 text-xs whitespace-nowrap">
                                <div class="flex flex-wrap gap-1">

                                    <div *ngFor="let skill of freelancer.skills; index as index"
                                        [class]="'flex text-xs items-center w-fit font-semibold rounded-full px-2 py-1'.concat(' ').concat(randomColor(skill))">
                                        {{skill}}
                                    </div>

                                </div>
                            </td>

                            <td class="px-4 py-4 text-sm whitespace-nowrap">
                                <app-ftable-dropdown [user]="freelancer"
                                    (deactivateAccount)="openDeactivateModal($event)"></app-ftable-dropdown>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>

    <div class="flex items-center justify-between ">
        <div class="text-sm text-gray-500">
            Page <span class="font-medium text-gray-700">1 of 10</span>
        </div>

        <div class="flex items-center mt-4 gap-x-4 sm:mt-0">
            <a href="#"
                class="flex items-center justify-center w-1/2 px-5 py-2 text-sm text-gray-700 capitalize transition-colors duration-200 bg-white border rounded-md sm:w-auto gap-x-2 hover:bg-gray-100 ">

                <ng-icon name="remixArrowLeftLine"></ng-icon>

                <span>
                    previous
                </span>
            </a>

            <a href="#"
                class="flex items-center justify-center w-1/2 px-5 py-2 text-sm text-gray-700 capitalize transition-colors duration-200 bg-white border rounded-md sm:w-auto gap-x-2 hover:bg-gray-100 ">
                <span>
                    Next
                </span>

                <ng-icon name="remixArrowRightLine"></ng-icon>
            </a>
        </div>
    </div>
</div>

<!-- Deactivate Acccount Confirmation Modal -->
<app-dialog (close)="closeModal()" [open]="open">
    <div class="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4 sm:rounded-lg">
        <div class="sm:flex sm:items-start">
            <div
                class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                <svg class="h-6 w-6 text-red-600" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                    stroke="currentColor" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round"
                        d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
                </svg>
            </div>
            <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                <h3 class="text-base font-semibold leading-6 text-gray-900" id="modal-title">Deactivate
                    "{{currentUser?.firstName}}" account</h3>
                <div class="mt-2">
                    <p class="text-sm text-gray-500">Are you sure you want to deactivate your account? This user data
                        will be permanently removed. This action cannot be undone.</p>
                </div>
            </div>
        </div>
    </div>
    <div class="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6 sm:rounded-b-lg">
        <button type="button"
            class="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto">Deactivate</button>
        <button (click)="open = false" type="button"
            class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto">Cancel</button>
    </div>
</app-dialog>
<!--  -->
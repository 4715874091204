<h1 class="text-2xl font-light text-secondary-900 mb-8">Withdrawals</h1>

<div class="space-y-6">
  <!-- Filter and Download -->
  <div class="flex items-center gap-x-3">
    <app-wtable-filter (onFilter)="setFilters($event)"></app-wtable-filter>
    <button class="btn-plain">
      <ng-icon name="remixDownload2Line"></ng-icon>

      <span>Download</span>
    </button>
  </div>
  <!--  -->

  <!-- Table Search -->
  <div class="flex items-center gap-2 flex-wrap w-full">
    <div class="relative flex items-center">
      <ng-icon
        class="absolute left-3 top-[25%] text-gray-400"
        name="remixSearch2Line"
      ></ng-icon>

      <input
        type="text"
        [(ngModel)]="searchValue"
        placeholder="Search"
        class="block w-full py-1.5 pr-5 pl-10 text-gray-700 bg-white rounded-md placeholder-gray-400/70 ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-secondary-500"
      />
    </div>
    <app-list-select
      [data]="searchFilters"
      (selectedList)="setLocale($event)"
    ></app-list-select>
  </div>
  <!--  -->

  <div class="overflow-x-auto">
    <div class="inline-block min-w-full py-2 align-middle">
      <div class="overflow-hidden border border-gray-200 md:rounded-md">
        <table class="min-w-full divide-y divide-gray-200">
          <thead class="bg-secondary-100/10">
            <tr>
              <th
                sortable="user.username"
                scope="col"
                (sort)="onSort($event)"
                class="uppercase px-4 py-3.5 text-sm font-semibold text-left rtl:text-right text-gray-500"
              >
                Name
              </th>

              <th
                sortable="user.email"
                scope="col"
                (sort)="onSort($event)"
                class="uppercase px-4 py-3.5 text-sm font-semibold text-left rtl:text-right text-gray-500"
              >
                Email
              </th>

              <th
                sortable="createdAt"
                scope="col"
                (sort)="onSort($event)"
                class="uppercase px-4 py-3.5 text-sm font-semibold text-left rtl:text-right text-gray-500"
              >
                Date
              </th>

              <th
                sortable="amount"
                scope="col"
                (sort)="onSort($event)"
                class="uppercase px-4 py-3.5 text-sm font-semibold text-left rtl:text-right text-gray-500"
              >
                Amount
              </th>

              <th
                sortable="status"
                scope="col"
                (sort)="onSort($event)"
                class="uppercase px-4 py-3.5 text-sm font-semibold text-left rtl:text-right text-gray-500"
              >
                Status
              </th>

              <th
                scope="col"
                class="uppercase px-4 py-3.5 text-sm font-semibold text-left rtl:text-right text-gray-500"
              >
                Actions
              </th>
            </tr>
          </thead>

          <tbody class="bg-white divide-y divide-gray-200">
            <tr
              *ngFor="
                let withdrawal of withdrawals
                  | withdrawalsSort : searchValue : searchLocale : tableFilters
              "
            >
              <td class="px-4 py-4 text-sm font-medium whitespace-nowrap">
                <div>
                  <h2 class="font-medium text-gray-800">
                    {{ withdrawal.user.firstName }}
                    {{ withdrawal.user.lastName }}
                  </h2>
                </div>
              </td>
              <td class="px-4 py-4 text-sm font-medium whitespace-nowrap">
                {{ withdrawal.user.email }}
              </td>
              <td class="px-4 py-4 text-sm whitespace-nowrap">
                <div>
                  <h4 class="text-gray-700 font-semibold">
                    {{ withdrawal.createdAt | date : "mediumDate" }}
                  </h4>
                </div>
              </td>
              <td class="px-4 py-4 text-sm font-semibold whitespace-nowrap">
                {{ withdrawal.amount }}
              </td>

              <td class="px-4 py-4 text-sm whitespace-nowrap">
                <div
                  [class]="
                    'font-semibold py-1 px-2 w-fit rounded-full text-xs uppercase '.concat(
                      getColor(withdrawal.status)
                    )
                  "
                >
                  {{ withdrawal.status }}
                </div>
              </td>

              <td class="px-4 py-4 text-sm whitespace-nowrap">
                <button
                  (click)="openWithdrawalModal({ state: true, withdrawal })"
                >
                  <ng-icon name="remixEyeFill" class="text-gray-500"></ng-icon>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <div class="flex items-center justify-between">
    <div class="text-sm text-gray-500">
      Page <span class="font-medium text-gray-700">1 of 10</span>
    </div>

    <div class="flex items-center mt-4 gap-x-4 sm:mt-0">
      <a
        href="#"
        class="flex items-center justify-center w-1/2 px-5 py-2 text-sm text-gray-700 capitalize transition-colors duration-200 bg-white border rounded-md sm:w-auto gap-x-2 hover:bg-gray-100"
      >
        <ng-icon name="remixArrowLeftLine"></ng-icon>

        <span> previous </span>
      </a>

      <a
        href="#"
        class="flex items-center justify-center w-1/2 px-5 py-2 text-sm text-gray-700 capitalize transition-colors duration-200 bg-white border rounded-md sm:w-auto gap-x-2 hover:bg-gray-100"
      >
        <span> Next </span>

        <ng-icon name="remixArrowRightLine"></ng-icon>
      </a>
    </div>
  </div>
</div>

<!-- Withdrawal Confirmation Modal -->
<app-dialog (close)="closeModal()" [open]="open">
  <div class="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4 sm:rounded-lg">
    <div class="sm:flex">
      <div class="mt-3 w-full sm:ml-4 sm:mt-0 text-left">
        <div class="flex justify-between items-center">
          <div class="flex items-center gap-2">
            <div
              class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-primary-100 sm:mx-0 sm:h-10 sm:w-10"
            >
              <ng-icon
                class="text-primary-600"
                name="remixWallet3Line"
              ></ng-icon>
            </div>
            <h3
              class="text-xl font-semibold leading-6 text-gray-900"
              id="modal-title"
            >
              Withdrawal Details
            </h3>
          </div>
          <button
            *ngIf="!editMode"
            (click)="toggleEditMode()"
            class="text-secondary-600 flex items-center gap-1 text-sm"
          >
            <ng-icon name="remixEditLine"></ng-icon>
            Edit
          </button>
        </div>

        <!-- View Mode -->
        <div *ngIf="!editMode" class="mt-4">
          <!-- User Info Section -->
          <div class="mb-4 pb-4 border-b border-gray-200">
            <h4 class="text-sm font-medium text-gray-500">User Information</h4>
            <div class="grid grid-cols-1 md:grid-cols-2 gap-4 mt-2">
              <div>
                <p class="text-xs font-medium text-gray-500">Name</p>
                <p class="text-sm font-medium">
                  {{ currentWithdrawal?.user?.firstName }}
                  {{ currentWithdrawal?.user?.lastName }}
                </p>
              </div>
              <div>
                <p class="text-xs font-medium text-gray-500">Email</p>
                <p class="text-sm font-medium">
                  {{ currentWithdrawal?.user?.email }}
                </p>
              </div>
              <div>
                <p class="text-xs font-medium text-gray-500">Username</p>
                <p class="text-sm font-medium">
                  {{ currentWithdrawal?.user?.username }}
                </p>
              </div>
              <div>
                <p class="text-xs font-medium text-gray-500">User ID</p>
                <p class="text-sm font-medium">
                  {{ currentWithdrawal?.user?.userId }}
                </p>
              </div>
            </div>
          </div>

          <!-- Bank Details Section -->
          <div
            *ngIf="currentWithdrawal?.bank"
            class="mb-4 pb-4 border-b border-gray-200"
          >
            <h4 class="text-sm font-medium text-gray-500">Bank Details</h4>
            <div class="grid grid-cols-1 md:grid-cols-2 gap-4 mt-2">
              <div>
                <p class="text-xs font-medium text-gray-500">Bank Name</p>
                <p class="text-sm font-medium">
                  {{ currentWithdrawal?.bank?.names }}
                </p>
              </div>
              <div>
                <p class="text-xs font-medium text-gray-500">Account Number</p>
                <p class="text-sm font-medium">
                  {{ currentWithdrawal?.bank?.number }}
                </p>
              </div>
              <div>
                <p class="text-xs font-medium text-gray-500">Bank Code</p>
                <p class="text-sm font-medium">
                  {{ currentWithdrawal?.bank?.code }}
                </p>
              </div>
              <div>
                <p class="text-xs font-medium text-gray-500">Status</p>
                <p class="text-sm font-medium">
                  <span
                    *ngIf="currentWithdrawal?.bank?.isApproved"
                    class="text-green-600"
                    >Approved</span
                  >
                  <span
                    *ngIf="currentWithdrawal?.bank?.isDenied"
                    class="text-red-600"
                    >Denied</span
                  >
                  <span
                    *ngIf="
                      !currentWithdrawal?.bank?.isApproved &&
                      !currentWithdrawal?.bank?.isDenied
                    "
                    class="text-gray-600"
                    >Pending</span
                  >
                </p>
              </div>
            </div>
          </div>

          <!-- Withdrawal Details -->
          <div class="mb-4 pb-4 border-b border-gray-200">
            <h4 class="text-sm font-medium text-gray-500">
              Withdrawal Details
            </h4>
            <div class="grid grid-cols-1 md:grid-cols-2 gap-4 mt-2">
              <div>
                <p class="text-xs font-medium text-gray-500">Amount</p>
                <p class="text-sm font-bold">{{ currentWithdrawal?.amount }}</p>
              </div>
              <div>
                <p class="text-xs font-medium text-gray-500 mb-2">Status</p>
                <div
                  [class]="
                    'font-semibold py-0.5 px-2 w-fit rounded-full text-xs uppercase ' +
                    getColor(currentWithdrawal?.status || '')
                  "
                >
                  {{ currentWithdrawal?.status }}
                </div>
              </div>
              <div>
                <p class="text-xs font-medium text-gray-500">Reference No</p>
                <p class="text-sm font-medium">
                  {{ currentWithdrawal?.refNo || "Not available" }}
                </p>
              </div>
              <div>
                <p class="text-xs font-medium text-gray-500">Date</p>
                <p class="text-sm font-medium">
                  {{ currentWithdrawal?.createdAt | date : "medium" }}
                </p>
              </div>
            </div>
          </div>

          <!-- Additional Information -->
          <div>
            <h4 class="text-sm font-medium text-gray-500">
              Additional Information
            </h4>
            <div class="grid grid-cols-1 md:grid-cols-2 gap-4 mt-2">
              <div>
                <p class="text-xs font-medium text-gray-500">To Wallet</p>
                <p class="text-sm font-medium">
                  {{ currentWithdrawal?.toWallet ? "Yes" : "No" }}
                </p>
              </div>
              <div>
                <p class="text-xs font-medium text-gray-500">From Self</p>
                <p class="text-sm font-medium">
                  {{ currentWithdrawal?.fromSelf ? "Yes" : "No" }}
                </p>
              </div>
            </div>
            <div class="mt-2">
              <p class="text-xs font-medium text-gray-500">Notes</p>
              <p class="text-sm font-medium">
                {{ currentWithdrawal?.narration || "No notes available" }}
              </p>
            </div>
            <div *ngIf="currentWithdrawal?.image" class="mt-3">
              <p class="text-xs font-medium text-gray-500">Payment Proof</p>
              <img
                [src]="'data:image/jpeg;base64,' + currentWithdrawal?.image"
                alt="Payment Proof"
                class="mt-1 border rounded-md max-w-xs max-h-48 object-contain"
              />
            </div>
            <div *ngIf="currentWithdrawal?.staff" class="mt-3">
              <p class="text-xs font-medium text-gray-500">Processed by</p>
              <p class="text-sm font-medium">
                {{ currentWithdrawal?.staff?.firstName }}
                {{ currentWithdrawal?.staff?.lastName }}
              </p>
            </div>
          </div>
        </div>

        <!-- Edit Mode -->
        <form
          *ngIf="editMode"
          [formGroup]="withdrawalForm"
          class="mt-4 space-y-4"
        >
          <div class="grid gap-4">
            <!-- Status Selection -->
            <div>
              <label class="block text-sm font-medium text-gray-700 mb-1"
                >Status</label
              >
              <select
                formControlName="status"
                class="w-full rounded-md border border-gray-300 py-2 px-3 text-gray-700 focus:outline-none focus:ring-2 focus:ring-secondary-500"
              >
                <option
                  *ngFor="let status of withdrawalStatuses"
                  [value]="status"
                >
                  {{ status }}
                </option>
              </select>
            </div>

            <!-- Reference Number -->
            <div>
              <label class="block text-sm font-medium text-gray-700 mb-1"
                >Reference Number</label
              >
              <input
                type="text"
                formControlName="refNo"
                placeholder="Transaction reference"
                class="w-full rounded-md border border-gray-300 py-2 px-3 text-gray-700 focus:outline-none focus:ring-2 focus:ring-secondary-500"
              />
            </div>

            <!-- Payment Proof (Image) -->
            <div>
              <label class="block text-sm font-medium text-gray-700 mb-1"
                >Payment Proof (Optional)</label
              >
              <input
                type="file"
                (change)="onFileSelected($event)"
                accept="image/*"
                class="w-full rounded-md border border-gray-300 py-2 px-3 text-gray-700 focus:outline-none focus:ring-2 focus:ring-secondary-500"
              />
              <p *ngIf="selectedFileName" class="text-xs text-gray-500 mt-1">
                {{ selectedFileName }}
              </p>
            </div>

            <!-- Narration/Notes -->
            <div>
              <label class="block text-sm font-medium text-gray-700 mb-1"
                >Notes (Optional)</label
              >
              <textarea
                formControlName="narration"
                rows="2"
                placeholder="Add processing notes"
                class="w-full rounded-md border border-gray-300 py-2 px-3 text-gray-700 focus:outline-none focus:ring-2 focus:ring-secondary-500"
              ></textarea>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div
    class="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6 sm:rounded-b-lg"
  >
    <!-- Edit Mode Buttons -->
    <ng-container *ngIf="editMode">
      <button
        (click)="processWithdrawal()"
        type="button"
        [disabled]="withdrawalForm.invalid || isProcessing"
        class="inline-flex justify-center rounded-md bg-secondary-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-secondary-500 w-fit ml-3 disabled:bg-gray-400"
      >
        <ng-icon
          *ngIf="isProcessing"
          name="remixLoader4Line"
          class="animate-spin mr-1"
        ></ng-icon>
        Update Withdrawal
      </button>
      <button
        (click)="toggleEditMode()"
        type="button"
        class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
      >
        Cancel
      </button>
    </ng-container>

    <!-- View Mode Buttons -->
    <ng-container *ngIf="!editMode">
      <button
        (click)="closeModal()"
        type="button"
        class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
      >
        Close
      </button>
    </ng-container>
  </div>
</app-dialog>

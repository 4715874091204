import { Pipe, PipeTransform } from '@angular/core';
import { CfilterConfig, Client } from '../../../types';

@Pipe({ name: 'clientsSort', standalone: true })
export class ClientsSortPipe implements PipeTransform {
  transform(
    values: Client[],
    search: string,
    locale: 'all' | 'email' | 'name' | 'username',
    filters: CfilterConfig
  ): Client[] {
    let searchResults;
    if (values.length === 0) {
      return values;
    }

    searchResults = values.filter((value: Client) => {
      const joinedName = value.firstName + ' ' + value.lastName;
      
      const nameFound =
        joinedName?.toLowerCase().indexOf(search.toLowerCase()) !== -1;

      const emailFound =
        value.email.toLowerCase().indexOf(search.toLowerCase()) !== -1;

      const usernameFound =
        value.username.toLowerCase().indexOf(search.toLowerCase()) !== -1;

      if (locale === 'all') {
        if (nameFound || emailFound || usernameFound) {
          return value;
        }
      }

      if (locale === 'name') {
        if (nameFound) {
          return value;
        }
      }

      if (locale === 'email') {
        if (emailFound) {
          return value;
        }
      }

      if (locale === 'username') {
        if (usernameFound) {
          return value;
        }
      }

      return;
    });

    if (!search || search.length === 0) {
      searchResults = values;
    }

    if (
      filters.jobsPosted.from === 0 &&
      !filters.jobsPosted.to &&
      filters.businessAreas.length === 0
    ) {
      return searchResults;
    }

    /*     const filterResults = searchResults.filter((value: Client) => {
      let amongJobsPosted
      if (filters.jobsPosted.to) {
        amongJobsPosted =
          value.jobsPosted >= filters.jobsPosted.from &&
          value.jobsPosted <= filters.jobsPosted.to;
      } else amongJobsPosted = value.jobsPosted >= filters.jobsPosted.from;

      let amongBusiness;

      if (filters.businessAreas.length === 0) {
        amongBusiness = true;
      } else {
        amongBusiness = filters.businessAreas.some((s) => s == value.businessName);
      }

      if (amongJobsPosted && amongBusiness) return value;
      else return;
    }); */

    return searchResults;
    // return filterResults;
  }
}

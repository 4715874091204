import { Inject, Injectable, PLATFORM_ID, inject } from '@angular/core';
import { VERIFY_TOKEN_MUTATION } from '../utils/graphql/auth-mutations';
import { ApiRequest } from '../utils/api-request';
import {
  BehaviorSubject,
  Observable,
  catchError,
  from,
  of,
  switchMap,
} from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
import { Router } from '@angular/router';

export const AUTH_USER_KEY = 'ngd-admin-auth-user';

export interface AuthUser {
  token: string;
  user: {
    id: string;
    email: string;
    username: string | null;
    firstName: string;
    lastName: string;
    isSuperuser: boolean;
    dateJoined: string;
    profileImage: string | null;
    lastLogin: string;
  };
}

@Injectable({
  providedIn: 'root',
})
export class UserStoreService {
  private loggedInSubject = new BehaviorSubject<boolean | null>(null);

  loggedIn$ = this.loggedInSubject.asObservable();

  constructor(
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.platformId = platformId;
  }

  clean(): void {
    localStorage.removeItem(AUTH_USER_KEY);
  }

  public saveUser(user: AuthUser): void {
    localStorage.removeItem(AUTH_USER_KEY);
    localStorage.setItem(AUTH_USER_KEY, JSON.stringify(user));
  }

  public getUser(): AuthUser | null {
    if (!isPlatformBrowser(inject(PLATFORM_ID))) {
      return null;
    }

    const user = localStorage.getItem(AUTH_USER_KEY);

    if (user) {
      return JSON.parse(user);
    }

    return null;
  }

  public isLoggedIn(): Observable<boolean> | null | boolean {
    if (!isPlatformBrowser(inject(PLATFORM_ID))) {
      return null;
    }

    const user = localStorage.getItem(AUTH_USER_KEY);

    if (!user) return false;

    const parsedUser = JSON.parse(user) as AuthUser;
    const token = parsedUser.token;

    if (!token) {
      this.clean();

      return false;
    }

    return this.verifyToken(token).pipe(
      switchMap((data) => {
        if (!data) {
          return of(false);
        } else return of(true);
      }),
      catchError((err) => {
        this.clean();

        this.router.navigateByUrl('/login');

        return of(false);
      })
    );
  }

  private verifyToken(token: string): Observable<any> {
    return from(ApiRequest(VERIFY_TOKEN_MUTATION, { token }, this.platformId, 'verifyToken'));
  }
}

import axios from "axios";
import { environment } from "../../environments/environment";
import { AUTH_USER_KEY, AuthUser } from "../services/user-store.service";
import { isPlatformBrowser } from '@angular/common';


export function ApiRequest(query: string, variables: { [key: string]: any } = {}, platformId: Object, core?: string, file?: any) {
  return new Promise<any>((resolve, reject) => {
    let headers: any
    let user: string | null = null
    

    if (isPlatformBrowser(platformId)) {
      user = localStorage.getItem(AUTH_USER_KEY);
    }

    if(user) {
      const parsedUser = JSON.parse(user) as AuthUser

      headers = parsedUser.token ? { Authorization: `JWT ${parsedUser.token}` } : {}
    } else {
      headers = {}
    }

    const data: any = { query, variables: variables ?? {} };
    if (file) {
      data.file = file;
      headers['Content-Type'] = 'multipart/form-data';
    }

    const apiUrl = "https://ydapi.ngddev.com/"
    axios.post(apiUrl, data, { headers })
      .then(res => {
        if (res.data.errors) {
          let errMsg = "Server Error"; // Default error message
          const errRes = res.data.errors;

          if (Array.isArray(errRes.message)) {
            errMsg = errRes.message[0].replace("GraphQL error:", "").trim();
          } else if (Array.isArray(errRes)) {
            errMsg = errRes[0].message.replace("GraphQL error:", "").trim();
          } else {
            errMsg = errRes?.message?.replace("GraphQL error:", "").trim() || errMsg;
          }

          reject(errMsg);
        } else {
          resolve(core ? res.data.data[core] : res.data.data);
        }
      })
      .catch(reject);
  });
}

<div
  *ngIf="open"
  class="z-50 fixed inset-0 w-screen overflow-y-auto"
  aria-labelledby="modal-title"
  role="dialog"
  aria-modal="true"
>
  <!-- Backdrop - Only close on click for desktop -->
  <div
    (click)="closeOnBackdrop($event)"
    class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity ease-in-out duration-300"
  ></div>

  <div
    class="flex min-h-full items-end justify-center p-0 text-center sm:items-center sm:p-4"
  >
    <!-- Dialog container -->
    <div
      class="relative transform bg-white text-left transition-all w-full h-screen sm:h-auto sm:rounded-lg sm:shadow-xl sm:my-8 sm:max-w-2xl"
    >
      <!-- Mobile close button -->
<!--       <button
        *ngIf="isMobile"
        (click)="closeModal()"
        class="fixed top-4 right-4 z-[60] p-2.5 bg-gray-100 rounded-full shadow-md sm:hidden"
        aria-label="Close dialog"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-5 w-5"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fill-rule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clip-rule="evenodd"
          />
        </svg>
      </button> -->

      <ng-content></ng-content>
    </div>
  </div>
</div>

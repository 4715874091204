
export const GET_ACTIVITY_LOGS_QUERY = `
query GetActivityLogs {
    adminActivityLogs {
      id
      user {
        id
        firstName
        lastName
        email
      }
      action
      details
      timestamp
    }
  }
`
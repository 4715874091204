import { Pipe, PipeTransform } from '@angular/core';
import { ActivityLog, ActivityFilterConfig } from '../../../types';

@Pipe({ name: 'activitySort', standalone: true })
export class ActivitySortPipe implements PipeTransform {
  transform(
    values: ActivityLog[],
    search: string,
    locale: 'all' | 'email' | 'name' | 'action',
    filters: ActivityFilterConfig
  ): ActivityLog[] {
    let searchResults;
    if (values.length === 0) {
      return values;
    }

    searchResults = values.filter((value: ActivityLog) => {
      console.log(value)
      const nameFound =
        value.user.firstName.toLowerCase().indexOf(search.toLowerCase()) !==
          -1 ||
        value.user.lastName.toLowerCase().indexOf(search.toLowerCase()) !== -1;

      const emailFound =
        value.user.email.toLowerCase().indexOf(search.toLowerCase()) !== -1;

      const actionFound =
        value.action.toLowerCase().indexOf(search.toLowerCase()) !== -1;

      const detailsFound =
        value.details.toLowerCase().indexOf(search.toLowerCase()) !== -1;

      if (locale === 'all') {
        if (nameFound || emailFound || actionFound || detailsFound) {
          return value;
        }
      }

      if (locale === 'name') {
        if (nameFound) {
          return value;
        }
      }

      if (locale === 'email') {
        if (emailFound) {
          return value;
        }
      }

      if (locale === 'action') {
        if (actionFound) {
          return value;
        }
      }

      return;
    });

    if (!search || search.length === 0) {
      searchResults = values;
    }

    if (!filters.date.from && !filters.date.to) {
      return searchResults;
    }

    const filterResults = searchResults.filter((value: ActivityLog) => {
      let amongdate;
      const date = new Date(value.timestamp);
      const dateTo = new Date(filters.date.to);

      if (filters.date.from) {
        const dateFrom = new Date(filters.date.from);

        amongdate = date >= dateTo && date <= dateFrom;
      } else amongdate = date >= dateTo;

      if (amongdate) return value;
      else return;
    });

    return filterResults;
  }
}

import { DatePipe, NgFor, NgIf } from '@angular/common';
import {
  Component,
  Inject,
  OnInit,
  PLATFORM_ID,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import {
  remixArrowLeftLine,
  remixArrowRightLine,
  remixDownload2Line,
  remixFilter3Line,
  remixSearch2Line,
  remixEyeFill,
  remixWallet3Line,
  remixLoader4Line,
  remixEditLine,  // Add this icon
} from '@ng-icons/remixicon';
import { WfilterConfig, Withdrawal } from '../../../types';
import {
  WsortableHeaderDirective,
  SortEvent,
  compare,
} from '../../directives/wsortable-header/wsortable-header.directive';
import { WithdrawalsSortPipe } from '../../pipes/withdrawals-sort/withdrawals-sort.pipe';
import { WtableFilterComponent } from '../../ui/wtable-filter/wtable-filter.component';
import { DialogComponent } from '../../ui/dialog/dialog.component';
import { ListSelectComponent } from '../../ui/list-select/list-select.component';
import { from } from 'rxjs';
import { ApiRequest } from '../../utils/api-request';
import { GET_WITHDRAWALS_QUERY } from '../../utils/graphql/withdrawals';
import { WITHDRAWAL_PROCESS_MUTATION } from '../../utils/graphql/withdrawal-mutations';
import { isPlatformBrowser } from '@angular/common';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-withdrawals',
  standalone: true,
  imports: [
    NgIconComponent,
    ListSelectComponent,
    NgFor,
    NgIf,
    WsortableHeaderDirective,
    WithdrawalsSortPipe,
    FormsModule,
    ReactiveFormsModule,
    DialogComponent,
    WtableFilterComponent,
    DatePipe,
  ],
  providers: [
    provideIcons({
      remixFilter3Line,
      remixDownload2Line,
      remixSearch2Line,
      remixArrowRightLine,
      remixArrowLeftLine,
      remixEyeFill,
      remixWallet3Line,
      remixLoader4Line,
      remixEditLine,  // Add this icon
    }),
  ],
  templateUrl: './withdrawals.component.html',
})
export class WithdrawalsComponent implements OnInit {
  @ViewChildren(WsortableHeaderDirective)
  headers!: QueryList<WsortableHeaderDirective>;

  currentWithdrawal?: Withdrawal;
  open: boolean = false;
  data: Withdrawal[] = [];
  withdrawals: Withdrawal[] = [];

  searchLocale: 'all' | 'email' | 'name' | 'username' = 'all';
  searchValue: string = '';
  searchFilters: string[] = ['All', 'Email', 'Name', 'Username'];
  tableFilters: WfilterConfig = {
    status: [],
  };

  // Withdrawal process
  withdrawalForm!: FormGroup;
  isProcessing = false;
  selectedFileName: string = '';
  selectedFile: File | null = null;
  withdrawalStatuses = ['APPROVED', 'PAID', 'REJECTED'];
  editMode: boolean = false;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private fb: FormBuilder,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.initWithdrawalForm();
    this.fetchWithdrawals();
  }

  fetchWithdrawals(): void {
    from(
      ApiRequest(GET_WITHDRAWALS_QUERY, {}, this.platformId, 'withdrawals')
    ).subscribe({
      next: (result) => {
        this.data = result;
        this.withdrawals = result;
      },
      error: (err) => {
        console.log(err);
      },
    });
  }

  initWithdrawalForm(): void {
    this.withdrawalForm = this.fb.group({
      status: ['APPROVED', Validators.required],
      refNo: ['', Validators.required],
      narration: [''],
    });
  }

  setLocale(locale: string) {
    this.searchLocale = locale as 'all' | 'email' | 'name' | 'username';
  }

  onSort({ column, direction }: SortEvent) {
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    if (direction === '' || column === '') {
      this.withdrawals = this.data;
    } else {
      this.withdrawals = [...this.data].sort((a, b) => {
        const getValue = (obj: any, path: string) =>
          path.split('.').reduce((o, p) => o && o[p], obj);
        const res = compare(getValue(a, column), getValue(b, column));
        return direction === 'asc' ? res : -res;
      });
    }
  }

  openWithdrawalModal(value: { state: boolean; withdrawal: Withdrawal }) {
    this.open = value.state;
    this.currentWithdrawal = value.withdrawal;
    this.editMode = false; // Start in view mode
    
    // Pre-populate the form with current values if they exist
    this.initWithdrawalForm();
    
    if (this.currentWithdrawal) {
      this.withdrawalForm.patchValue({
        status: this.currentWithdrawal.status || 'APPROVED',
        refNo: this.currentWithdrawal.refNo || '',
        narration: this.currentWithdrawal.narration || ''
      });
    }
    
    this.selectedFile = null;
    this.selectedFileName = '';
  }

  toggleEditMode(): void {
    this.editMode = !this.editMode;
    
    // If switching to edit mode, ensure form is properly populated
    if (this.editMode && this.currentWithdrawal) {
      this.withdrawalForm.patchValue({
        status: this.currentWithdrawal.status || 'APPROVED',
        refNo: this.currentWithdrawal.refNo || '',
        narration: this.currentWithdrawal.narration || ''
      });
    }
  }
  
  closeModal() {
    this.open = false;
    this.currentWithdrawal = undefined;
    this.editMode = false;
  }

  setFilters(filter: WfilterConfig) {
    this.tableFilters = filter;
  }

  getColor(status: string): string {
    status = status.toLowerCase()
    
    if (status == 'approved') return 'text-secondary-700 bg-secondary-100';
    else if (status == 'paid') return 'text-green-700 bg-green-100';
    else if (status == 'rejected') return 'text-red-700 bg-red-100';
    return 'text-gray-700 bg-gray-100';
  }

  onFileSelected(event: any): void {
    if (!isPlatformBrowser(this.platformId)) return;
    
    const file = event.target.files[0];
    if (file) {
      this.selectedFile = file;
      this.selectedFileName = file.name;
    }
  }
  
  async processWithdrawal(): Promise<void> {
    if (this.withdrawalForm.invalid || !this.currentWithdrawal) {
      return;
    }
    
    this.isProcessing = true;
    
    try {
      // Convert image to base64 if present
      let base64Image = '';
      if (this.selectedFile && isPlatformBrowser(this.platformId)) {
        base64Image = await this.convertFileToBase64(this.selectedFile);
      }
      
      const variables = {
        id: this.currentWithdrawal.id,
        status: this.withdrawalForm.value.status,
        refNo: this.withdrawalForm.value.refNo,
        // image: base64Image,
        narration: this.withdrawalForm.value.narration
      };
      
      from(ApiRequest(
        WITHDRAWAL_PROCESS_MUTATION, 
        variables, 
        this.platformId, 
        'withdrawalProcess.res'
      )).subscribe({
        next: (result) => {
          const status = this.withdrawalForm.value.status.toLowerCase();
          this.toastr.success(`Withdrawal successfully ${status}`);
          this.closeModal();
          this.fetchWithdrawals(); 
        },
        error: (err) => {
          this.toastr.error('Failed to process withdrawal: ' + err.message);
          this.isProcessing = false;
        },
        complete: () => {
          this.isProcessing = false;
        }
      });
    } catch (error: any) {
      this.toastr.error('An error occurred: ' + error.message);
      this.isProcessing = false;
    }
  }
  
  convertFileToBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64String = reader.result as string;
        const base64Content = base64String.split(',')[1]; // Remove the data:image/xyz;base64, prefix
        resolve(base64Content);
      };
      reader.onerror = (error) => reject(error);
    });
  }
}

import { Freelancer } from './types';

export const freelancersData: Freelancer[] = [
  {
    id: '1',
    firstName: 'Adeola',
    lastName: 'Adekunle',
    email: 'adloa@gmail.com',
    username: 'adeola_adekunle',
    jobsDone: 10,
    earning: '250,000',
    skills: ['UI/UX', 'Graphic Design'],
    rating: 3.3,
  },
  {
    id: '2',
    firstName: 'Chinedu',
    lastName: 'Okoro',
    email: 'chinedu@example.com',
    username: 'chinedu_okoro',
    jobsDone: 15,
    earning: '350,000',
    skills: ['Web Design', 'Illustration'],
    rating: 4.8,
  },
  {
    id: '3',
    firstName: 'Ngozi',
    lastName: 'Onyeka',
    email: 'ngozi@example.com',
    username: 'ngozi_onyeka',
    jobsDone: 20,
    earning: '500,000',
    skills: ['Logo Design', 'Branding'],
    rating: 4.5,
  },
];

export const designFields: string[] = [
  'UI/UX',
  'Graphic Design',
  'Web Design',
  'Illustration',
  'Logo Design',
  'Branding',
  'Print Design',
  'Typography',
  'Motion Graphics',
  'Animation',
];

export const designFieldsColors: { [key: string]: string } = {
  'UI/UX': 'text-blue-700 bg-blue-100',
  'Graphic Design': 'text-purple-700 bg-purple-100',
  'Web Design': 'text-green-700 bg-green-100',
  Illustration: 'text-yellow-700 bg-yellow-100',
  'Logo Design': 'text-accent-700 bg-accent-100',
  Branding: 'text-secondary-700 bg-secondary-100',
  'Print Design': 'text-primary-700 bg-primary-100',
  Typography: 'text-gray-700 bg-gray-100',
  'Motion Graphics': 'text-amber-700 bg-amber-100',
  Animation: 'text-cyan-700 bg-cyan-100',
};

import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  PLATFORM_ID,
  Inject,
} from '@angular/core';
import { RouterLink } from '@angular/router';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import {
  remixArrowRightSLine,
  remixFlashlightLine,
  remixHome2Line,
  remixInstanceLine,
  remixSettings2Line,
  remixWallet3Line,
  remixContractLeftLine,
  remixBox2Line,
  remixBarChartGroupedLine,
} from '@ng-icons/remixicon';
import { featherSidebar } from "@ng-icons/feather-icons"
import { Page } from '../../../types';
import { SidebarLinkComponent } from './components/sidebar-link/sidebar-link.component';
import { NgClass, NgFor, isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-sidebar',
  standalone: true,
  imports: [NgIconComponent, RouterLink, SidebarLinkComponent, NgFor, NgClass],
  providers: [
    provideIcons({
      remixHome2Line,
      remixInstanceLine,
      remixArrowRightSLine,
      remixFlashlightLine,
      remixSettings2Line,
      remixWallet3Line,
      remixContractLeftLine,
      remixBox2Line,
      remixBarChartGroupedLine,
      featherSidebar
    }),
  ],
  templateUrl: './sidebar.component.html',
})
export class SidebarComponent implements OnInit {
  @Input({ required: true }) pages!: Page[];
  @Input({ required: true }) collapse!: boolean;
  @Output() emitCollapse = new EventEmitter<boolean>();

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
    this.platformId = platformId;

    if (isPlatformBrowser(this.platformId)) {
      window.addEventListener('resize', () => {
        const main = document.querySelector('#dashboard-main')!;
        const sidebarOverlay = document.querySelector('#sidebar-overlay')!;
        const sidebarMenu = document.querySelector('#sidebar-menu')!;

        if (window.innerWidth <= 768) {
          if (this.collapse) {
            this.collapse = false;
            this.emitCollapse.emit(this.collapse);
            localStorage.setItem(
              'ngd-admin',
              JSON.stringify({ open: this.collapse })
            );
          }
          main.classList.add('active');
          sidebarOverlay.classList.add('hidden');
          sidebarMenu.classList.add('-translate-x-full');
        } else {
          main.classList.remove('active');
          sidebarOverlay.classList.remove('hidden');
          sidebarMenu.classList.remove('-translate-x-full');
        }
      });
    }
  }

  // Resize the sidebar on application initialization
  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      const main = document.querySelector('#dashboard-main')!;
      const sidebarOverlay = document.querySelector('#sidebar-overlay')!;
      const sidebarMenu = document.querySelector('#sidebar-menu')!;

      if (window.innerWidth <= 768) {
        main.classList.add('active');
        sidebarOverlay.classList.add('hidden');
        sidebarMenu.classList.add('-translate-x-full');
      } else {
        main.classList.remove('active');
        sidebarOverlay.classList.remove('hidden');
        sidebarMenu.classList.remove('-translate-x-full');
      }
    }
  }

  toggleSidebarOverlay(e: MouseEvent) {
    if (isPlatformBrowser(this.platformId)) {
      const main = document.querySelector('#dashboard-main')!;
      const sidebarOverlay = document.querySelector('#sidebar-overlay')!;
      const sidebarMenu = document.querySelector('#sidebar-menu')!;

      e.preventDefault();

      main.classList.add('active');
      sidebarOverlay.classList.add('hidden');
      sidebarMenu.classList.add('-translate-x-full');
    }
  }

  openDrop(open: boolean) {
    if (isPlatformBrowser(this.platformId)) {
      this.collapse = false;
      this.emitCollapse.emit(this.collapse);
      localStorage.setItem(
        'ngd-admin',
        JSON.stringify({ open: this.collapse })
      );
    }
  }

  toggleCollapse() {
    if (isPlatformBrowser(this.platformId)) {
      this.collapse = !this.collapse;
      this.emitCollapse.emit(this.collapse);
      localStorage.setItem(
        'ngd-admin',
        JSON.stringify({ open: this.collapse })
      );
    }
  }
}

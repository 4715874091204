import { DatePipe, NgFor, NgIf, isPlatformBrowser } from '@angular/common';
import {
  Component,
  Inject,
  OnInit,
  PLATFORM_ID,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import {
  remixArrowLeftLine,
  remixArrowRightLine,
  remixDownload2Line,
  remixFilter3Line,
  remixSearch2Line,
} from '@ng-icons/remixicon';
import { ActivityLog, ActivityFilterConfig } from '../../../types';
import {
  LogsSortableHeaderDirective,
  SortEvent,
  compare,
} from '../../directives/logs-sortable-header/logs-sortable-header.directive';
import { ActivitySortPipe } from '../../pipes/activity-logs-sort/activity-logs-sort.pipe';
import { ActivityTableFilterComponent } from '../../ui/activity-table-filter/activity-filter.component';
import { DialogComponent } from '../../ui/dialog/dialog.component';
import { ListSelectComponent } from '../../ui/list-select/list-select.component';
import { GET_ACTIVITY_LOGS_QUERY } from '../../utils/graphql/activities-queries';
import { from } from 'rxjs';
import { ApiRequest } from '../../utils/api-request';
import { PaginationComponent } from '../../ui/pagination/pagination.component';

@Component({
  selector: 'app-activity-logs',
  standalone: true,
  imports: [
    NgIconComponent,
    ListSelectComponent,
    NgFor,
    NgIf,
    LogsSortableHeaderDirective,
    ActivitySortPipe,
    FormsModule,
    DialogComponent,
    ActivityTableFilterComponent,
    DatePipe,
    PaginationComponent,
  ],
  providers: [
    provideIcons({
      remixFilter3Line,
      remixDownload2Line,
      remixSearch2Line,
      remixArrowRightLine,
      remixArrowLeftLine,
    }),
  ],
  templateUrl: './activity-logs.component.html',
})
export class ActivityLogsComponent implements OnInit {
  @ViewChildren(LogsSortableHeaderDirective)
  headers!: QueryList<LogsSortableHeaderDirective>;

  currentActivity?: ActivityLog;
  open: boolean = false;
  loading: boolean = true;
  data: ActivityLog[] = [];
  adminActivityLogs: ActivityLog[] | null = null;

  searchLocale: 'all' | 'email' | 'name' | 'action' = 'all';
  searchValue: string = '';
  searchFilters: string[] = ['All', 'Email', 'Name', 'Username', 'Action'];
  tableFilters: ActivityFilterConfig = {
    date: { from: undefined, to: Date.now().toString() },
  };

  currentPage: number = 1;
  itemsPerPage: number = 10;
  itemsPerPageOptions: number[] = [5, 10, 20, 50, 100];
  totalPages: number = 1;

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
    this.platformId = platformId;
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      from(
        ApiRequest(
          GET_ACTIVITY_LOGS_QUERY,
          {},
          this.platformId,
          'adminActivityLogs'
        )
      ).subscribe({
        next: (result) => {
          this.data = result;
          // this.adminActivityLogs = result;
          this.totalPages = Math.ceil(this.data.length / this.itemsPerPage);
          this.updateTableData();
        },
        error: (err) => {
          console.log(err);
        },
      });
    }
  }

  setLocale(locale: string) {
    this.searchLocale = locale as 'all' | 'email' | 'name' | 'action';
  }

  onSort({ column, direction }: SortEvent) {
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    if (direction === '' || column === '') {
      this.adminActivityLogs = this.data;
    } else {
      this.adminActivityLogs = [...this.data].sort((a, b) => {
        const res = compare(a[column], b[column]);

        return direction === 'asc' ? res : -res;
      });
    }

    this.updateTableData();
  }

  openDeactivateModal(value: { state: boolean; user: ActivityLog }) {
    this.open = value.state;
    this.currentActivity = value.user;
  }

  closeModal() {
    this.open = false;
  }

  setFilters(filter: ActivityFilterConfig) {
    this.tableFilters = filter;
  }

  updateTableData() {
    this.totalPages = Math.ceil(this.data.length / this.itemsPerPage);
    if (this.currentPage > this.totalPages) {
      this.currentPage = this.totalPages;
    }
    const start = (this.currentPage - 1) * this.itemsPerPage;
    const end = start + this.itemsPerPage;
    this.adminActivityLogs = this.data.slice(start, end);
  }

  onPageChange(page: number) {
    this.currentPage = page;
    this.updateTableData();
  }

  onItemsPerPageChange(itemsPerPage: number) {
    this.itemsPerPage = itemsPerPage;
    this.currentPage = 1;
    this.updateTableData();
  }
}

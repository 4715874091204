import { Routes } from '@angular/router';
import { setLayout } from './layouts/layout.resolver';
import { PageLayout } from '../types';
import { LoginComponent } from './pages/login/login.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { FreelancersComponent } from './pages/users/freelancers/freelancers.component';
import { ClientsComponent } from './pages/users/clients/clients.component';
import { WithdrawalsComponent } from './pages/withdrawals/withdrawals.component';
import { PortfoliosComponent } from './pages/portfolios/portfolios.component';
import { AuthGuard } from './guards/auth.guard';
import { ActivityLogsComponent } from './pages/activity-logs/activity-logs.component';

export const routes: Routes = [
  {
    path: 'login',
    resolve: {
      layout: setLayout(PageLayout.Center),
    },
    component: LoginComponent,
  },
  {
    path: '',
    resolve: {
      layout: setLayout(PageLayout.Main),
    },
    component: DashboardComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'users',
    resolve: {
      layout: setLayout(PageLayout.Main),
    },
    children: [
      { path: '', pathMatch: 'full', redirectTo: 'freelancers' },
      {
        path: 'freelancers',
        component: FreelancersComponent,
        // canActivate: [AuthGuard]
      },
      {
        path: 'clients',
        component: ClientsComponent,
        // canActivate: [AuthGuard],
      },
    ],
    canActivate: [AuthGuard]
  },
  {
    path: 'withdrawals',
    resolve: {
      layout: setLayout(PageLayout.Main),
    },
    component: WithdrawalsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'portfolios',
    resolve: {
      layout: setLayout(PageLayout.Main),
    },
    component: PortfoliosComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'activity-logs',
    resolve: {
      layout: setLayout(PageLayout.Main),
    },
    component: ActivityLogsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: '**',
    resolve: {
      layout: setLayout(PageLayout.Blank),
    },
    pathMatch: 'full',
    component: NotFoundComponent,
  },
];

export const GET_CLIENTS_QUERY = `
query GetClients {
  allClients {
    id
    firstName
    lastName
    username
    email
    businessName
    companyLogo
    businessType
    accountType
  }
}
`;

import { NgClass } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Output,
  ViewChild,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { remixEdit2Line, remixEyeLine } from '@ng-icons/remixicon';
import { createPopper } from '@popperjs/core';
import { ActivityFilterConfig } from '../../../types';
import { MultiSelectComponent } from '../multi-select/multi-select.component';

@Component({
  selector: 'app-activity-filter',
  standalone: true,
  imports: [NgClass, FormsModule, NgIconComponent, MultiSelectComponent],
  providers: [provideIcons({ remixEyeLine, remixEdit2Line })],
  templateUrl: './activity-filter.component.html',
})
export class ActivityTableFilterComponent implements AfterViewInit {
  @Output() onFilter = new EventEmitter<ActivityFilterConfig>();
  dropdownPopoverShow = false;

  dateFrom?: string;
  dateTo: string = '2024-05-08T11:50:37.252Z'
  businessAreas: string[] = [];

  @ViewChild('btnDropdownRef', { static: false }) btnDropdownRef!: ElementRef;
  @ViewChild('popoverDropdownRef', { static: true })
  popoverDropdownRef!: ElementRef;

  ngAfterViewInit() {
    createPopper(
      this.btnDropdownRef.nativeElement,
      this.popoverDropdownRef.nativeElement,
      {
        placement: 'bottom-start',
      }
    );
  }

  toggleDropdown(event: MouseEvent) {
    event.preventDefault();

    if (this.dropdownPopoverShow) {
      this.dropdownPopoverShow = false;
    } else {
      this.dropdownPopoverShow = true;
    }
  }

  setSelectedLists(lists: string[]) {
    this.businessAreas = lists;
  }

  applyFilters() {
    this.onFilter.emit({
      date: { from: this.dateFrom, to: this.dateTo },
    });

    this.dropdownPopoverShow = false;
  }

  clearFilters() {
    this.dateFrom = undefined;
    this.dateTo = '2024-05-08T11:50:37.252Z'
    this.businessAreas = [];
  }
}

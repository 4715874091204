import { isPlatformBrowser, NgFor, NgIf } from '@angular/common';
import {
  Component,
  HostListener,
  Inject,
  PLATFORM_ID,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import {
  remixArrowLeftLine,
  remixArrowRightLine,
  remixDownload2Line,
  remixFilter3Line,
  remixSearch2Line,
  remixEyeFill,
  remixWallet3Line,
  remixEyeLine,
} from '@ng-icons/remixicon';
import { WfilterConfig, Portfolio } from '../../../types';

import { DialogComponent } from '../../ui/dialog/dialog.component';
import { ListSelectComponent } from '../../ui/list-select/list-select.component';
import { from } from 'rxjs';
import { ApiRequest } from '../../utils/api-request';
import { GET_PORTFOLIOS_QUERY } from '../../utils/graphql/porfolios-query';
import { ToastrService } from 'ngx-toastr';
import { PageSpinnerComponent } from '../../ui/spinners/page-spinner/page-spinner.component';

@Component({
  selector: 'app-portfolios',
  standalone: true,
  imports: [
    NgIconComponent,
    ListSelectComponent,
    NgFor,
    NgIf,
    FormsModule,
    DialogComponent,
    PageSpinnerComponent
  ],
  providers: [
    provideIcons({
      remixFilter3Line,
      remixDownload2Line,
      remixSearch2Line,
      remixArrowRightLine,
      remixArrowLeftLine,
      remixEyeFill,
      remixWallet3Line,
      remixEyeLine,
    }),
  ],
  templateUrl: './portfolios.component.html',
})
export class PortfoliosComponent {
  currentPortfolio?: Portfolio;
  open: boolean = false;
  portfolios?: Portfolio[] = [];

  loading = false;
  endCursor: string | null = null;
  hasNextPage = true; // Track if more pages exist

  searchValue: string = '';

  constructor(
    private toastr: ToastrService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.platformId = platformId;

    if (isPlatformBrowser(this.platformId)) {
      this.fetchData();
    }
  }

  openPortfoliosodal(value: { state: boolean; user: Portfolio }) {
    this.open = value.state;
    this.currentPortfolio = value.user;
  }

  closeModal() {
    this.open = false;
  }

  fetchData() {
    if (this.loading || !this.hasNextPage) return;

    this.loading = true;
    const variables = this.endCursor
      ? { after: this.endCursor, first: 10 }
      : { first: 10 };

    from(
      ApiRequest(
        GET_PORTFOLIOS_QUERY,
        variables,
        'yourPlatformId',
        'portfolios'
      )
    ).subscribe({
      next: (result) => {
        const edges = result.edges || [];

        // Extract nodes and merge with existing data
        this.portfolios = [
          ...(this.portfolios || []),
          ...edges.map((edge: { node: Portfolio }) => edge.node),
        ];

        // Update pagination info
        this.endCursor = result.pageInfo?.endCursor || null;
        this.hasNextPage = result.pageInfo?.hasNextPage || false;
        this.loading = false;
      },
      error: (err) => {
        this.toastr.error(err, 'Error fetching portfolios');
        this.loading = false;
      },
    });
  }

  @HostListener('window:scroll', [])
  onScroll() {
    if (
      window.innerHeight + window.scrollY >=
      document.body.offsetHeight - 200
    ) {
      this.fetchData();
    }
  }
}

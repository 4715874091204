export const WITHDRAWAL_PROCESS_MUTATION = `
mutation WithdrawalProcessMutation($id: String!, $status: String!, $refNo: String, $image: String, $narration: String) {
  withdrawalProcess(data: { id: $id, status: $status, refNo: $refNo, image: $image, narration: $narration }) {
    res {
      id
      balanceAtWithdrawal
      amount
      staff {
        id
        firstName
        lastName
      }
      createdAt
      updatedAt
    }
  }
}
`;
<li class="mb-3 group">
    
    <div #dlink (click)="dropdownToggle(dlink)"
        class="flex text-gray-700 hover:bg-gray-100 hover:text-gray-700 rounded-md group-[.active]:text-gray-700 group-[.selected]:text-gray-700 sidebar-dropdown-toggle cursor-pointer"
        [ngClass]="collapse ? 'py-2 px-2 items-center justify-center' : 'py-2 px-4 items-center'"
        routerLinkActive="bg-secondary-600 text-gray-100"
        *ngIf="page.subPages && page.subPages.length > 0; else textLink">
        <ng-icon [name]="page.icon" size="1.25rem" class="text-lg text-gray-500" [ngClass]="collapse ? 'mr-0' : 'mr-3'"></ng-icon>

        <span *ngIf="!collapse" class="text-sm whitespace-nowrap break-keep">{{ page.title }}</span>

        <ng-icon *ngIf="collapse ? false : (page.subPages && page.subPages.length > 0)" name="remixArrowRightSLine"
            class="ml-auto group-[.selected]:rotate-90"></ng-icon>
    </div>

    <ng-template #textLink>
        <a [routerLink]="page.link"
        routerLinkActive="bg-secondary-600 text-gray-100"
            class="flex text-gray-700 hover:bg-gray-100 hover:text-gray-700 rounded-md group-[.active]:text-white group-[.selected]:text-gray-100 sidebar-dropdown-toggle"
            [ngClass]="collapse ? 'py-2 px-2 items-center justify-center' : 'py-2 px-4 items-center'">
            <ng-icon [name]="page.icon" size="1.25rem" class="text-lg text-gray-500" [ngClass]="collapse ? 'mr-0' : 'mr-3'"></ng-icon>

            <span *ngIf="!collapse" class="text-sm">{{ page.title }}</span>
        </a></ng-template>

    <ul *ngIf="collapse ? false : (page.subPages && page.subPages.length > 0)"
        class="list-none pl-12 mt-2 hidden group-[.selected]:block">
        <li class="mb-4 list-none" *ngFor="let data of page.subPages">
            <a [routerLink]="data.link"
                class="text-gray-700 text-sm flex items-center hover:text-gray-600 before:contents-[''] before:w-1 before:h-1 before:rounded-full before:bg-gray-300 before:mr-3">{{
                data.title }}</a>
        </li>
    </ul>
</li>